import {AfterViewInit, Component, Input, OnInit, ViewChild, ViewEncapsulation} from '@angular/core';
import {FormBuilder, FormControl, Validators} from '@angular/forms';
import {UsersInsuranceService} from '../../services/usersinsurance/users.insurance.service';
import {UsersService} from '../../services/users/users.service';
import {MatTabGroup} from '@angular/material/tabs';
import {UiAlertService} from '../../services/ui-alert/ui-alert.service';
import {ToastComponent} from '../toast/toast.component';
import {UtilsService} from '../../services/utils/utils.service';

@Component({
  selector: 'app-insurance-dependents',
  templateUrl: './insurance-dependents.component.html',
  styleUrls: ['./insurance-dependents.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class InsuranceDependentsComponent implements OnInit, AfterViewInit {

  @Input() vendorProduct;

  // @ts-ignore
  @ViewChild('tabs') tabGroup: MatTabGroup;

  public dependents: any;
  public addTabName = 'Add Dependent';
  private counter = 1;
  public selectedTabIndex = 0;
  public saving = false;
  protected hasPrimary;

  constructor(
      private formBuilder: FormBuilder,
      private usersInsuranceService: UsersInsuranceService,
      private usersService: UsersService,
      private uiAlertService: UiAlertService,
      private toastComponent: ToastComponent,
      private utilsService: UtilsService
  ) {
  }

  buildForm(dependent: any): void {
    dependent.form = this.formBuilder.group({
      gender: new FormControl(dependent.gender, Validators.required),
      tobaccoUseDate: new FormControl(dependent.tobaccoUseDate),
      relationship: new FormControl(dependent.relationship, Validators.required),
      firstName: new FormControl(dependent.firstName, Validators.required),
      lastName: new FormControl(dependent.lastName, Validators.required),
      dob: new FormControl(dependent.dob, Validators.required),
    });
    dependent.initialFormValues = JSON.parse(JSON.stringify(dependent.form.value));
    dependent.form.get('firstName').valueChanges.subscribe((value: any) => {
      dependent.firstName = value;
      this.dependents = this.dependents.slice();
    });
  }

  ngOnInit(): void {
    this.usersInsuranceService.getInsurancePrimary(this.usersService.getCurrentUserId(), this.vendorProductId()).then(result => {
      this.hasPrimary = !!result.id;
    });
    this.usersInsuranceService.usersInsuranceObservable.subscribe(_ => {
      this.hasPrimary = true;
    });
    this.usersInsuranceService.getInsuranceDependents(this.usersService.getCurrentUserId(), this.vendorProductId()).then(result => {
      this.dependents = result.concat({firstName: this.addTabName});
      this.dependents.forEach((dependent: any) => this.buildForm(dependent));
    });
  }

  ngAfterViewInit(): void {
    this.setTabClass();
    this.tabGroup.selectedIndex = 0;
  }

  onTabChanged(event): void {
    this.setTabClass();
  }

  setTabClass(): void {
    const elements = document.querySelectorAll('.mat-tab-label');
    elements.forEach(function(element) {
      if (element instanceof HTMLElement) {
        element.style.opacity = '1';
      }
    });
  }

  onClick(dependent: any): void {
    if (dependent.firstName === this.addTabName) {
      this.dependents.unshift({firstName: ``});
      this.buildForm(this.dependents[0]);
      this.tabGroup.selectedIndex = 1;
      setTimeout(() => {
        this.tabGroup.selectedIndex = 0;
      }, 50);
    }
  }

  resetClicked(dependent: any): void {
    dependent.form.reset();
    // tslint:disable-next-line:forin
    for (const field in dependent.initialFormValues) {
      dependent.form.get(field).setValue(dependent.initialFormValues[field]);
    }
  }

  saveClicked(dependent: any): void {
    const newInfo = {
      gender: dependent.form.get('gender')?.value,
      tobaccoUseDate: this.utilsService.convertDate(dependent.form.get('tobaccoUseDate')?.value),
      firstName: dependent.form.get('firstName')?.value,
      lastName: dependent.form.get('lastName')?.value,
      dob: this.utilsService.convertDate(dependent.form.get('dob')?.value),
      relationship: dependent.form.get('relationship')?.value
    };
    this.saving = true;
    if (dependent.id && !this.dependents[0].fromWallit) {
      this.usersInsuranceService.modifyInsuranceDependent(this.usersService.getCurrentUserId(), dependent.id, newInfo).then(_ => {
        this.saving = false;
        this.toastComponent.presentToast('User insurance dependent info updated');
        this.resetClicked(dependent);
      });
    } else {
      this.usersInsuranceService.createInsuranceDependent(
          this.usersService.getCurrentUserId(),
          this.vendorProductId(),
          dependent.form.get('gender')?.value,
          {},
          this.utilsService.convertDate(dependent.form.get('tobaccoUseDate')?.value),
          dependent.form.get('firstName')?.value,
          dependent.form.get('lastName')?.value,
          this.utilsService.convertDate(dependent.form.get('dob')?.value),
          dependent.form.get('relationship')?.value
      ).then(_ => {
        this.saving = false;
        dependent = Object.assign({}, dependent, newInfo);
        this.toastComponent.presentToast('User insurance dependent info created');
        this.resetClicked(dependent);
      });
    }
  }

  removeClicked(dependent: any): void {
    if (dependent.id) {
      this.uiAlertService.presentAlertConfirm('Do you really want to remove this dependent?').then(ok => {
        if (ok) {
          this.usersInsuranceService.deleteInsuranceDependent(this.usersService.getCurrentUserId(), dependent.id).then(_ => {
            this.toastComponent.presentToast('Dependent removed');
            this.dependents.splice(this.dependents.findIndex((item: { id: string; }) => item.id === dependent.id), 1);
            this.dependents = this.dependents.slice();
          });
        }
      });
    } else {
      this.toastComponent.presentToast('Dependent removed');
      this.dependents.splice(this.tabGroup.selectedIndex, 1);
      this.dependents = this.dependents.slice();
    }
  }

  vendorProductId() {
    return this.vendorProduct?.id || 'wallit';
  }

}
