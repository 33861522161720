import { Injectable } from '@angular/core';
import { ApiService} from '../api/api.service';
import {Restangular} from 'ngx-restangular';
import { JwtHelperService } from '@auth0/angular-jwt';
import {UsersService} from '../users/users.service';
import {AccountsService} from '../accounts/accounts.service';
import {TasksService} from '../tasks/tasks.service';
import {GoalsService} from '../goals/goals.service';
import * as moment from 'moment/moment';
import {LocalStorageServiceProvider} from '../local-storage-service/local-storage-service';
import {APP_VERSION} from '../../version.js';
import {HttpClient, HttpParams} from '@angular/common/http';
import { AppSettings } from 'src/app/app.settings';
import {ActivatedRoute, Params, Router, RouterStateSnapshot} from '@angular/router';
import {Observable} from 'rxjs';
import {CognitoMfaService} from '../cognito/cognito-mfa.service';
import {AccountInterface} from '../../interfaces/user-account/create-account.interface';

@Injectable({
  providedIn: 'root'
})
export class SessionService {

  public signupMode = false;
  public invitationMode = false;
  public loginUrl;
  public currentRouteBrandParam = '';

  JWT_TOKEN = 'JWTToken';
  REFRESH_TOKEN = 'refreshToken';

  constructor(
      private apiService: ApiService,
      private localStorageService: LocalStorageServiceProvider,
      private restangular: Restangular,
      private usersService: UsersService,
      private accountsService: AccountsService,
      private tasksService: TasksService,
      private goalsService: GoalsService,
      private http: HttpClient,
      private route: ActivatedRoute,
      private cognitoMFAService: CognitoMfaService,
      private router: Router
  ) {
    const _this = this;
    function checkApi() {
      return _this.restangular.one('health-check').get().toPromise().then((data) => {
        ApiService.hideWaiting();
      }).catch(error => {
        if (error.status !== 0) {
          // not a network down error
          ApiService.showWaiting('Oops! Looks like we are experiencing some issues. We’re working to solve the problem ASAP. Please check back soon!');
        }
      });
    }
    /*
    checkApi();
    setInterval(checkApi, 60000);
     */
    this.route.queryParams.subscribe((params: Params): void => {
      this.currentRouteBrandParam = params['brand'];
    });
  }

  isAppUpToDate(): Promise<any> {
    return this.restangular.one('health-check').get().toPromise().then(data => {
      return data.appVersion === APP_VERSION;
    });
  }

  login(user): Promise<any> {
    return this.restangular.all('auth').post(user).toPromise().then((data) => {
      this.setToken(data.id, data.refreshToken);
      return data;
    });
  }

  async loginUserPassword(user: {username: string, password: string}): Promise<any> {
    const params: HttpParams = this.currentRouteBrandParam ? new HttpParams().set('brand', this.currentRouteBrandParam) : null;
    await this.cognitoAuthMethods(user).then((): void => {
      this.http.post(`${AppSettings.getEndpoint()}/auth/user-password`, user, {
        params: params,
        withCredentials: true
      }).toPromise().then((data: any) => {
        this.parseTokens(data);
        return data;
      });
    }).catch(err =>  {
      console.error('Error in cognito auth method: ', err);
      return Promise.reject(`'Error in cognito auth method: ', ${err}`);
    });
  }

  async cognitoAuthMethods(user: {username: string, password: string}): Promise<void> {
    try {
      const userAlreadyExist: boolean | null = await this.cognitoMFAService.checkIfUserExist(user);
      if (userAlreadyExist === null) {
        return Promise.reject();
      }
      if (userAlreadyExist) {
        const cognitoUserResponse: any = await this.cognitoMFAService.cognitoMFASignIn(user);
        if (cognitoUserResponse && cognitoUserResponse.challengeName === 'SOFTWARE_TOKEN_MFA' && !cognitoUserResponse.deviceKey) {
          void this.router.navigate(['2fa/app/add-code', user.username, 'secret'], {state: user});
          return Promise.reject('SOFTWARE_TOKEN_MFA Required');
        }
      } else {
        await this.cognitoMFAService.cognitoMFASignUp(user);
        await this.cognitoMFAService.cognitoMFASignIn(user);
      }
    } catch (error) {
      return Promise.reject(error);
    }
  }

  linkChildSignup(user: any, invite: string): Promise<any> {
    return this.restangular.one('auth').all('user-password').post(user, {invite: invite}).toPromise().then((data) => {
      this.parseTokens(data);
      return data;
    });
  }

  linkChildSMSSignup(user: any, invite: string): Promise<any> {
    return this.restangular.one('auth').all('mobile').post(user, {invite: invite}).toPromise().then((data) => {
      return data;
    });
  }

  private getAge(dateString): number {
    const today = new Date();
    const birthDate = new Date(dateString);
    let age = today.getFullYear() - birthDate.getFullYear();
    const m = today.getMonth() - birthDate.getMonth();
    if (m < 0 || (m === 0 && today.getDate() < birthDate.getDate())) {
      age--;
    }
    return age;
  }

  private setPermissions(user) {
    user.permissionLevel = !user.birthdate || this.getAge(user.birthdate) >= 18 ? 8 : 1;
  }

  async createAccount(user: AccountInterface): Promise<any> {
    this.setPermissions(user);
    return this.restangular.one('auth').all('create-user-password').post(user).toPromise().then(async (data: any) => {
      const userCredential: {username: string, password: string} = {username: user.username, password: user.password};
      await this.cognitoMFAService.cognitoMFASignUp(userCredential);
      await this.cognitoMFAService.cognitoMFASignIn(userCredential);
      this.setToken(data.id, data.refreshToken);
      return data;
    });
  }

  createSMS(user): Promise<any> {
    user.firstName = '-';
    user.lastName = '-';
    this.setPermissions(user);
    return this.restangular.all('auth').all('mobile').post(user).toPromise();
  }

   loginSMS(number: string): Promise<any> {
    return this.http.post(`${AppSettings.getEndpoint()}/auth/mobile`, number, {withCredentials: true}).toPromise();
  }

  resendSMS(number: string): Promise<any> {
    return this.restangular.all('auth').one('mobile', number).customPOST({}).toPromise();
  }

  deletePhoneNumber(number: string): Promise<any> {
    return this.restangular.one('mobile', number).remove().toPromise();
  }

  async loginValidateCode(phoneNumber: string, code: string): Promise<any> {
    return this.restangular.all('auth').one('mobile', phoneNumber).one('verificationCode', code).get().toPromise().then((data) => {
      this.parseTokens(data);
      return data;
    });
  }

  setTimeZone(userId: string) {
    const timezone = moment(moment.utc().toDate()).local().format('Z');
    return this.restangular.one('users', userId).one('notifications-settings').one('timezone', timezone).customPUT().toPromise();
  }

  logout() {
    this.apiService.unsetToken();
    this.localStorageService.removeStorage(this.JWT_TOKEN);
    this.localStorageService.removeStorage(this.REFRESH_TOKEN);
  }

  public setToken(token, refreshToken = null) {
    this.apiService.setToken(token);
    this.localStorageService.set(this.JWT_TOKEN, token);
    if (refreshToken) {
      this.localStorageService.set(this.REFRESH_TOKEN, refreshToken);
    }
    this.usersService.reloadMe();
    this.tasksService.reload();
    this.goalsService.reload();
    this.accountsService.reload();
  }

  public refreshToken() {
    const data = {
      refreshToken: this.localStorageService.get(this.REFRESH_TOKEN)
    };
    return this.restangular.one('auth').all('refresh').post(data).toPromise().then((response) => {
      this.setToken(response.id, response.refreshToken);
      return data;
    });
  }

  private parseTokens(data) {
    const parts = data.url.split('/').slice(-2);
    this.setToken(parts[0], parts[1]);
  }

  public decodeJWTToken(jwt = this.localStorageService.get('JWTToken')) {
    try {
      const helper = new JwtHelperService();
      return helper.decodeToken(jwt);
    } catch (err) {
      return null;
    }
  }

  public getUserId() {
    const token = this.decodeJWTToken();
    return token ? token.userId : null;
  }

  public getTokens(): Array<string> {
    return [this.localStorageService.get(this.JWT_TOKEN), this.localStorageService.get(this.REFRESH_TOKEN)];
  }

  sendEmail(email: string): Observable<any> {
    const params: HttpParams = this.currentRouteBrandParam ? new HttpParams().set('brand', this.currentRouteBrandParam) : null;
    return this.http.post(`${AppSettings.getEndpoint()}/auth/login-without-pass`, {email: email}, { params: params, withCredentials: true });
  }

  async verifyEmailCode(email: string, code: string): Promise<any> {
    const params: HttpParams = this.currentRouteBrandParam ? new HttpParams().set('brand', this.currentRouteBrandParam) : null;
    return this.http.post(`${AppSettings.getEndpoint()}/auth/login-without-pass/${code}`, {email: email}, { params: params, withCredentials: true }).toPromise().then((data: any) => {
      this.setToken(data.id, data.refreshToken);
      return data;
    });
  }

  clearLocalStorageAndRedirectUser(state: RouterStateSnapshot): void {
    this.loginUrl = state.url;
    localStorage.clear();
    void this.router.navigate(['/SignIn']);
  }

}
