import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {AppSettings} from '../../app.settings';
import {BehaviorSubject} from 'rxjs';
import {SecretsInterface} from '../../interfaces/secrets/secrets.interface';

@Injectable({
    providedIn: 'root'
})
export class SecretService {

    private _secrets: BehaviorSubject<SecretsInterface> = new BehaviorSubject(null);

    constructor(private http: HttpClient) {
        if (!this._secrets.getValue()) {
            this.getAppSecrets();
        }
    }

    get secrets(): BehaviorSubject<SecretsInterface> {
        return this._secrets;
    }

    getAppSecrets(): void {
        this.http.get<SecretsInterface>(`${AppSettings.getEndpoint()}/secrets`).subscribe({
            next: (secrets: SecretsInterface): void => {
                this._secrets.next(secrets);
            },
            error: err => {
                console.error('Error retrieving secrets', err);
            }
        });
    }


}
