import { Injectable } from '@angular/core';
import {Dictionary} from 'highcharts';
import {VendorsService} from '../vendors/vendors.service';
import {ToastComponent} from '../../components/toast/toast.component';
import {Router} from '@angular/router';
import Handlebars from 'handlebars/dist/cjs/handlebars';
import {StripeService} from '../stripe/stripe.service';
import {RewardsService} from '../rewards/rewards.service';
import {UsersService} from '../users/users.service';
import {StorageServiceProvider} from '../storage-service/storage-service';
import {CryptoService} from '../crypto/crypto.service';

@Injectable({
  providedIn: 'root'
})
export class PurchaseService {

  static actionClicked = false;

  constructor(
      private vendorsService: VendorsService,
      private toastComponent: ToastComponent,
      private router: Router,
      private stripeService: StripeService,
      private rewardsService: RewardsService,
      private usersService: UsersService,
      private storage: StorageServiceProvider,
      private cryptoService: CryptoService
  ) { }

  doPurchase(vendorProduct: Dictionary<any>, action: Dictionary<any>) {
    this.vendorsService.enrollProduct(vendorProduct.vendorSlug, vendorProduct.productSlug, {arguments: Object.assign({}, action.arguments, {testOnly: true})}).then(result => {
      if (result.errors && result.errors.length > 0) {
        const profileSection = ['profile', 'primary', 'dependents', 'beneficiaries'][result.errors[0].source];
        this.toastComponent.presentToast('You must provide additional information before you can purchase this product: ' + result.errors.map(error => `${['account', 'primary', 'dependents', 'beneficiaries'][error.source]}${error.source === 2 || error.source === 3 ? '(' + error.index + ')' : ''}: ${error.message}`).join(', '));
        PurchaseService.actionClicked = false;
        this.router.navigate([`EditProfile/${profileSection}/${vendorProduct.id}`]);
      } else {
        console.log('DOPURCHASE', action.type);
        if (action.type === 'vendorStripe') {
          this.purchaseUsingVendorStripe(vendorProduct, action);
        } else {
          try {
            console.log('VENDORPRODUCT', vendorProduct)
            console.log('ACTION', action)
            this.purchaseByQuantity(action, this.parseHandlebars(vendorProduct.stripeProductId || ''), parseInt(this.parseHandlebars(action.arguments.quantity || '0'), 10), vendorProduct.stripeVendor, _ => {
              console.log('DOENROLL')
              this.doEnroll(vendorProduct, action);
            });
          } catch (error) {
            console.log(error)
          }
        }
      }
    }).catch (error => {
      this.toastComponent.presentToast(error.error);
    });
  }

  purchaseByQuantity(action, productId, count, stripePaymentVendor = null, doneCallback = null): void {
    console.log('PURCHASEBYQUANTITY', action, productId, count);
    if (count === 0 || this.usersService.me().user.isDemo) {
      if (this.usersService.me().user.isDemo) {
        this.isDemoNotice();
      }
      if (doneCallback) {
        doneCallback();
      }
      return;
    }
    this.stripeService.getProduct(productId).then(product => {
      let totalPrice = 0;
      switch (product.price.billing_scheme) {
        case 'tiered':
          switch (product.price.tiers_mode) {
            case 'graduated':
              totalPrice = this.calculateTotalGraduatedPrice(count, product.price.tiers);
              break;
            case 'volume':
              totalPrice = this.calculateTotalVolumePrice(count, product.price.tiers);
              break;
          }
          break;
        case 'per_unit':
          totalPrice = product.price.unit_amount * count;
          break;
      }
      totalPrice /= 100;
      this.rewardsService.purchaseProduct(productId, count, totalPrice, action, doneCallback);
    });
  }

  private calculateTotalGraduatedPrice(quantity, priceTiers) {
    let total = 0;
    let remainingQuantity = quantity;
    for (const tier of priceTiers) {
      const { unit_amount, up_to } = tier;
      if (up_to === null || remainingQuantity <= up_to) {
        total += remainingQuantity * unit_amount;
        break;
      } else {
        total += up_to * unit_amount;
        remainingQuantity -= up_to;
      }
      if (tier.flat_amount) {
        total += tier.flat_amount;
      }
    }
    return total;
  }

  private calculateTotalVolumePrice(quantity, priceTiers) {
    for (const tier of priceTiers) {
      const { unit_amount, up_to } = tier;
      if (up_to == null || up_to >= quantity) {
        return tier.unit_price * quantity;
      }
    }
  }

  doEnroll(vendorProduct: Dictionary<any>, action: Dictionary<any>) {
    this.vendorsService.enrollProduct(vendorProduct.vendorSlug, vendorProduct.productSlug, {arguments: action.arguments}).then(launchResult => {
      console.log('ENROLL RESULT', launchResult);
      PurchaseService.actionClicked = false;
      if (launchResult.error) {
        this.stripeService.getUserPaymentInfo().then(paymentInfo => {
          this.stripeService.getPurchasedProducts(paymentInfo.stripeCustomerId).then(products => {
            const product = products.find(thisProduct => thisProduct.items.data[0].plan.product === vendorProduct.stripeProductId);
            if (product) {
              this.stripeService.cancelSubscription(product.items.data[0].subscription).then(() => {
                this.toastComponent.presentToast(`Enrollment error: ${launchResult.error}. Your payment has been refunded. Please contact customer support.`);
              });
            } else {
              this.toastComponent.presentToast(`Enrollment error: ${launchResult.error}. Please contact customer support.`);
            }
            PurchaseService.actionClicked = false;
          });
        });
      } else {
        this.rewardsService.afterPurchase(action);
      }
    }).catch (error => {
      this.toastComponent.presentToast(error.error);
    });;
  }

  parseHandlebars(value): string {
    function buildDOMValuesDict(element) {
      const domValues = {};
      if (element.id) {
        domValues[element.id] = element.value;
      }
      for (let i = 0; i < element.children?.length; i++) {
        const child = element.children[i];
        const childValues = buildDOMValuesDict(child);
        Object.assign(domValues, childValues);
      }
      return domValues;
    }
    const template = Handlebars.compile(value.toString());
    const namedTags = buildDOMValuesDict(document.getElementsByClassName('descriptionhtml')[0]);
    return(template({dom: namedTags}));
  }

  isDemoNotice() {
    this.toastComponent.presentToast(`You are a demo user. Payment bypassed.`);
  }

  purchaseUsingVendorStripe(vendorProduct, args) {
    if (this.usersService.me().user.isDemo) {
      // bypass payments in demo mode
      console.log('DEMO MODE')
      this.isDemoNotice();
      this.doEnroll(vendorProduct, args);
      return;
    }
    this.storage.set('vendorProduct', vendorProduct);
    this.storage.set('args', args);
    console.log('ARGS', vendorProduct, args);
    const urlParts = this.router.url.split('/');
    urlParts.length = 4;
    this.stripeService.getVendorCheckoutSessionID(args.arguments.vendor, vendorProduct.metadata.enroll.premium, args.arguments.name, vendorProduct.metadata.enroll.plan, urlParts.join('/')).then(result => {
      window.location.href = result.url;
    });
  }

  purchaseUsingVendorStripeSuccess(result, action) {
    this.router.navigate(['embeddedpage', this.cryptoService.encodeJSON({url: result.url})]);
  }

  purchaseUsingVendorStripeFailure(result, action) {
    this.vendorsService.cancelProductEnrollment(action.arguments.vendor, action.arguments.product, {arguments: {employeeId: result.employeeId}});
    this.toastComponent.presentToast(`Your payment failed. No product purchased`);
  }

}
