import {AfterViewInit, Component, Input, OnInit, ViewChild} from '@angular/core';
import {FormBuilder, FormControl, Validators} from '@angular/forms';
import {UsersInsuranceService} from '../../services/usersinsurance/users.insurance.service';
import {UsersService} from '../../services/users/users.service';
import {MatTabGroup} from '@angular/material/tabs';
import {UiAlertService} from '../../services/ui-alert/ui-alert.service';
import {ToastComponent} from '../toast/toast.component';
import {UtilsService} from '../../services/utils/utils.service';

@Component({
  selector: 'app-insurance-beneficiaries',
  templateUrl: './insurance-beneficiaries.component.html',
  styleUrls: ['./insurance-beneficiaries.component.scss'],
})
export class InsuranceBeneficiariesComponent implements OnInit, AfterViewInit {

  @Input() vendorProduct;

  // @ts-ignore
  @ViewChild('tabs') tabGroup: MatTabGroup;

  public beneficiaries: any;
  public addTabName = 'Add Beneficiary';
  private counter = 1;
  public selectedTabIndex = 0;
  public saving = false;
  protected hasPrimary;

  constructor(
      private formBuilder: FormBuilder,
      private usersInsuranceService: UsersInsuranceService,
      private usersService: UsersService,
      private uiAlertService: UiAlertService,
      private toastComponent: ToastComponent,
      private utilsService: UtilsService
  ) {
  }

  buildForm(beneficiary: any): void {
    beneficiary.form = this.formBuilder.group({
      relationship: new FormControl(beneficiary.relationship, Validators.required),
      beneficiaryType: new FormControl(beneficiary.beneficiaryType, Validators.required),
      firstName: new FormControl(beneficiary.firstName, Validators.required),
      lastName: new FormControl(beneficiary.lastName, Validators.required),
      ssn: new FormControl(beneficiary.ssn),
    });
    beneficiary.initialFormValues = JSON.parse(JSON.stringify(beneficiary.form.value));
    beneficiary.form.get('firstName').valueChanges.subscribe((value: any) => {
      beneficiary.firstName = value;
      this.beneficiaries = this.beneficiaries.slice();
    });
  }

  ngOnInit(): void {
    this.usersInsuranceService.getInsurancePrimary(this.usersService.getCurrentUserId(), this.vendorProductId()).then(result => {
      this.hasPrimary = !!result.id;
    });
    this.usersInsuranceService.usersInsuranceObservable.subscribe(_ => {
      this.hasPrimary = true;
    });
    this.usersInsuranceService.getInsuranceBeneficiaries(this.usersService.getCurrentUserId(), this.vendorProductId()).then(result => {
      this.beneficiaries = result.concat({firstName: this.addTabName});
      this.beneficiaries.forEach((beneficiary: any) => this.buildForm(beneficiary));
    });
  }

  ngAfterViewInit(): void {
    this.setTabClass();
    this.tabGroup.selectedIndex = 0;
  }

  onTabChanged(event): void {
    this.setTabClass();
  }

  setVal(beneficiary: any, value: any, frmControlName: string) {
    beneficiary.form.get(frmControlName).setValue(value);
    beneficiary.form.get(frmControlName).markAsDirty();
  }

  setTabClass(): void {
    const elements = document.querySelectorAll('.mat-tab-label');
    elements.forEach(function(element) {
      if (element instanceof HTMLElement) {
        element.style.opacity = '1';
      }
    });
  }

  onClick(beneficiary: any): void {
    if (beneficiary.firstName === this.addTabName) {
      this.beneficiaries.unshift({firstName: ``});
      this.buildForm(this.beneficiaries[0]);
      this.tabGroup.selectedIndex = 1;
      setTimeout(() => {
        this.tabGroup.selectedIndex = 0;
      }, 50);
    }
  }

  resetClicked(beneficiary: any): void {
    beneficiary.form.reset();
    // tslint:disable-next-line:forin
    for (const field in beneficiary.initialFormValues) {
      console.log('FIELD', field, beneficiary.initialFormValues[field]);
      beneficiary.form.get(field).setValue(beneficiary.initialFormValues[field]);
    }
  }

  saveClicked(beneficiary: any): void {
    const newInfo = {
      relationship: beneficiary.form.get('relationship')?.value,
      type: beneficiary.form.get('beneficiaryType')?.value,
      firstName: beneficiary.form.get('firstName')?.value,
      lastName: beneficiary.form.get('lastName')?.value,
      ssn: beneficiary.form.get('ssn')?.value
    };
    this.saving = true;
    if (beneficiary.id && !this.beneficiaries[0].fromWallit) {
      this.usersInsuranceService.modifyInsuranceBeneficiary(this.usersService.getCurrentUserId(), beneficiary.id, newInfo).then(_ => {
        this.saving = false;
        this.toastComponent.presentToast('User insurance beneficiary info updated');
      });
    } else {
      this.usersInsuranceService.createInsuranceBeneficiary(
          this.usersService.getCurrentUserId(),
          this.vendorProductId(),
          beneficiary.form.get('firstName')?.value,
          beneficiary.form.get('lastName')?.value,
          this.utilsService.convertDate(beneficiary.form.get('ssn')?.value),
          beneficiary.form.get('relationship')?.value,
          beneficiary.form.get('beneficiaryType')?.value
      ).then(_ => {
        beneficiary = newInfo;
        this.saving = false;
        this.toastComponent.presentToast('User insurance beneficiary info created');
      });
    }
  }

  removeClicked(beneficiary: any): void {
    if (beneficiary.id) {
      this.uiAlertService.presentAlertConfirm('Do you really want to remove this beneficiary?').then(ok => {
        if (ok) {
          this.usersInsuranceService.deleteInsuranceBeneficiary(this.usersService.getCurrentUserId(), beneficiary.id).then(_ => {
            this.toastComponent.presentToast('beneficiary removed');
            this.beneficiaries.splice(this.beneficiaries.findIndex((item: { id: string; }) => item.id === beneficiary.id), 1);
            this.beneficiaries = this.beneficiaries.slice();
          });
        }
      });
    } else {
      this.toastComponent.presentToast('beneficiary removed');
      this.beneficiaries.splice(this.tabGroup.selectedIndex, 1);
      this.beneficiaries = this.beneficiaries.slice();
    }
  }

  vendorProductId() {
    return this.vendorProduct?.id || 'wallit';
  }

}
