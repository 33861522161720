import {Component, Input, OnInit, Pipe, PipeTransform} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {UsersService} from '../../services/users/users.service';
import {LedgerService} from '../../services/ledger/ledger.service';
import {AccountsService} from '../../services/accounts/accounts.service';
import {UtilsService} from '../../services/utils/utils.service';
import {ContentService} from '../../services/content/content-service.service';
import {WallitService} from '../../services/wallit/wallit.service';
import {BrandsService} from '../../services/brands/brands.service';
import {ApiService} from '../../services/api/api.service';

@Component({
  selector: 'app-transfer-history',
  templateUrl: './transfer-history.page.html',
  styleUrls: ['./transfer-history.page.scss'],
})
export class TransferHistoryComponent implements OnInit {

  @Input() enabled = true;

  familyId: string;
  userId: string;
  bankId: string;

  protected wallitBalance;
  protected dwollaBalance;
  protected familyBalance;
  protected pendingInboundTransferBalance;
  protected pendingOutboundTransferBalance;
  protected familyLedger: any;
  private transactions = [];
  protected groups = [];
  protected spinnerLevel;

  constructor(
      private route: ActivatedRoute,
      private router: Router,
      protected usersService: UsersService,
      private utilsService: UtilsService,
      private ledgerService: LedgerService,
      private accountsService: AccountsService,
      private contentService: ContentService,
      private brandsService: BrandsService,
      private wallitService: WallitService,
      private apiService: ApiService
  ) {
  }

  ngOnInit() {
    this.apiService.spinnerSubscribe(val => this.spinnerLevel = val);
    this.familyId = this.usersService.getCurrentFamilyId();
    this.userId = this.usersService.getCurrentUserId();
    this.getTransfersForUserId(this.userId);
  }

  private getTransfersForUserId(userId: string) {
    this.accountsService.getTransactions(this.familyId, userId).then(data => {
      data = data.filter(
        (transaction) =>
          transaction.sourceData?.destinationUserId === userId &&
          transaction.transactionStatus !== "begin" &&
          transaction.transactionStatus !== "error" &&
          transaction.bankType !== "allowanceReward"
      );
      data = data.reduce(function (accum, current) {
        const foundIndex = accum.findIndex(item => item.transactionUUID === current.transactionUUID);
        if (foundIndex < 0) {
          accum.push(current);
        } else {
          if (accum[foundIndex].transactionStatus !== 'begin') {
            accum[foundIndex] = current;
          }
        }
        return accum;
      }, []);
      data.forEach(transaction => {
        transaction.label = 'Transfer';
        switch (transaction.transactionType) {
          case 'withdraw':
            transaction.amount = -transaction.amount;
            transaction.sourceData.name = 'Wallit Balance';
            break;
          case 'DwollaBonusScript':
            if (transaction.sourceData.extraInfo) {
              switch (transaction.sourceData.extraInfo.type) {
                case 'clo':
                  transaction.cashbackInfo = `From ${transaction.sourceData.extraInfo.vendor}`;
                  transaction.sourceData.name = 'Cash Back';
                  transaction.label = 'Card-Linked Cash Back';
                  break;
                case 'wallit':
                  transaction.cashbackInfo = `Adjustment from Wallit for team ${transaction.extraInfo.team}`;
                  transaction.label = 'Adjustment from Wallit';
                  break;
                case 'team':
                  transaction.cashbackInfo = `For ${transaction.sourceData.extraInfo.transaction}`;
                  transaction.label = `Team Reimbursement from  ${transaction.sourceData.extraInfo.team}`;
                  break;
              }
            }
            break;
          case 'tangoCard':
            transaction.cashbackInfo = 'Gift card purchase';
            transaction.amount = -transaction.amount;
            break;
          case 'fromAllowance':
            transaction.cashbackInfo = `From team ${transaction.sourceData.extraInfo.name}`;
            break;
        }
      });
      let balance = 0;
      data.reverse().forEach(item => {
        item.userId = userId;
        item.by = this.utilsService.getUserName(this.usersService.lookupFamilyMember(userId));
        balance += parseFloat(item.amount);
        item.balance = balance;
        item.date = item.createdAt;
      });
      data.reverse();
      this.transactions = this.transactions.concat(data);
      this.transactions.sort(function (a, b) {
        if (a.createdAt < b.createdAt) {
          return -1;
        }
        if (a.createdAt > b.createdAt) {
          return 1;
        }
        return 0;
      });
      this.groups = this.utilsService.buildGroups(this.transactions);
    });
  }

  cashbackInfo(transaction) {
    return transaction.cashbackInfo;
  }

  getMemberIcon(member) {
    return this.usersService.getAvatarOld(this.usersService.lookupFamilyMember(member.userId));
  }

  getMemberName(data): string {
    return data.name;
  }

  getMemberBalance(data): number {
    return data.balance.value;
  }

  transactionClicked(transaction) {
    this.router.navigate(['transaction-detail', this.familyId, transaction.userId, transaction.id]);
  }

}

@Pipe({
  name: 'getLine1',
  pure: true
})
export class GetTransferHistoryLine1Pipe implements PipeTransform {

  transform(item: any, thisArg: TransferHistoryComponent): string {
    if (typeof item.sourceData === 'string') {
      try{
        item.sourceData = JSON.parse(item.sourceData);
      } catch (e) {
        item.sourceData = {name: 'Undefined transfer'};
      }
    }
    if (item.sourceData.type === 'withdraw') {
      return 'Transferred to your connected account';
    } else {
      return item.sourceData.tangoGiftCardOrder ? 'Transferred from your Wallit Balance' : 'Transferred to your Wallit Balance';
    }
  }

}

@Pipe({
  name: 'getLine2',
  pure: true
})
export class GetTransferHistoryLine2Pipe implements PipeTransform {

  transform(item: any, thisArg: TransferHistoryComponent): string {
    return thisArg.cashbackInfo(item);
  }

}

@Pipe({
  name: 'getCardClass',
  pure: true
})
export class GetCardClassPipe implements PipeTransform {

  transform(item: any): string {
      let extras = '';
      switch (item.transactionStatus) {
        case 'cancelled':
          extras = 'cancelled';
          break;
        case 'failed':
          extras = 'failed';
          break;
        case 'processed':
          extras = 'pending';
      }
      return 'transfercard ' + extras;
  }
}

@Pipe({
  name: 'getTransactionLabel',
  pure: true
})
export class GetTransactionLabelPipe implements PipeTransform {

  transform(transaction: any): string {
    return transaction.label;
  }
}
